var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"header bg-white border-bottom"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body border-0"},[_c('div',{staticClass:"row align-items-end"},[_vm._m(0),_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"btn btn-primary mr-2 ml-2",attrs:{"to":{
								name: 'CreateBatchBooking',
							}}},[_vm._v("Create Batch Booking")])],1)])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('v-server-table',{ref:"batchBookingTable",attrs:{"selectable":"","columns":_vm.columns,"options":_vm.options},on:{"row-click":_vm.showBatchBookingRequest},scopedSlots:_vm._u([{key:"Pickup",fn:function(props){return _c('div',{},[(props.row.booking_data.meta)?_c('span',[_vm._v(_vm._s(props.row.booking_data.meta.pickup)+" ")]):_vm._e()])}},{key:"Dropoff",fn:function(props){return _c('div',{},[(props.row.booking_data.meta)?_c('span',[_vm._v(_vm._s(props.row.booking_data.meta.destination))]):_vm._e()])}},{key:"Route Code",fn:function(props){return _c('div',{},[(props.row.booking_data.meta)?_c('span',[_vm._v(_vm._s(props.row.booking_data.meta.route_code))]):_vm._e()])}},{key:"Users Count",fn:function(props){return _c('div',{},[_c('span',[_vm._v(_vm._s(props.row.users ? props.row.users.length : 0))])])}},{key:"Status",fn:function(props){return _c('div',{},[_c('div',{staticClass:"badge",class:{
										'badge-success': props.row.processing_completed_at,
										'badge-warning': !props.row.processing_completed_at,
									}},[_vm._v(" "+_vm._s(props.row.processing_completed_at ? "processed" : "pending")+" ")])])}},{key:"Created At",fn:function(props){return _c('div',{},[_c('span',[_vm._v(_vm._s(props.row.created_at))])])}}])})],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('h6',{staticClass:"header-pretitle"},[_vm._v("Overview")]),_c('h1',{staticClass:"header-title"},[_vm._v("Batch Booking Requests")])])
}]

export { render, staticRenderFns }