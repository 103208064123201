<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Batch Booking Requests</h1>
						</div>

						<div class="col-auto">
							<router-link
								:to="{
									name: 'CreateBatchBooking',
								}"
								class="btn btn-primary mr-2 ml-2"
								>Create Batch Booking</router-link
								>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div>
				<div class="row mt-4">
					<div class="col-12">
						<div class="card">
							<v-server-table
								ref="batchBookingTable"
								selectable
								:columns="columns"
								:options="options"
								@row-click="showBatchBookingRequest"
								>
								<div slot="Pickup" slot-scope="props">
									<span v-if="props.row.booking_data.meta"
										>{{ props.row.booking_data.meta.pickup }}
									</span>
								</div>
								<div slot="Dropoff" slot-scope="props">
									<span v-if="props.row.booking_data.meta">{{
										props.row.booking_data.meta.destination
									}}</span>
								</div>
								<div slot="Route Code" slot-scope="props">
									<span v-if="props.row.booking_data.meta">{{
										props.row.booking_data.meta.route_code
									}}</span>
								</div>
								<div slot="Users Count" slot-scope="props">
									<span>{{ props.row.users ? props.row.users.length : 0 }}</span>
								</div>
								<div slot="Status" slot-scope="props">
									<div
										class="badge"
										:class="{
											'badge-success': props.row.processing_completed_at,
											'badge-warning': !props.row.processing_completed_at,
										}"
										>
										{{ props.row.processing_completed_at ? "processed" : "pending" }}
									</div>
								</div>
								<div slot="Created At" slot-scope="props">
									<span>{{ props.row.created_at }}</span>
								</div>
							</v-server-table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
export default {
  components: {},
  data () {
    return {
      columns: ['Pickup', 'Dropoff', 'Route Code', 'Users Count', 'Status', 'Created At'],
      options: {
        filterable: false,
        requestFunction: (data) => {
          return this.fetchBatchBookingRequests(data)
        }
      },
      range: []
    }
  },
  watch: {
    range (value) {
      if (value) {
        this.$refs.batchBookingTable.refresh()
      }
    }
  },
  computed: {
    dateFilterValid () {
      return this.range.length && this.range.filter((date) => !!date).length > 0
    },
    dateRange () {
      return this.range.map((date) => moment(date).format('YYYY-MM-DD'))
    }
  },
  methods: {
    async fetchBatchBookingRequests (params) {
      const limit = params ? params.limit : 10
      const page = params ? params.page : 1
      const query = params ? params.query : ''

      const baseUrl = '/v1/batch-bookings'

      let url = `${baseUrl}?limit=${limit}&page=${page}&metadata=true&`

      if (this.dateFilterValid) {
        url += `from=${this.dateRange[0]}&to=${this.dateRange[1]}`
      }

      const result = await this.axios({
        url,
        method: 'GET',
        data: {
          search_term: query
        }
      })

      let data = typeof result.data.data !== 'undefined' ? result.data.data : result.data

      data = data.map((item) => {
        if (item.users) {
          item.users_count = item.users.length
        }

        if (item.booking_data && item.booking_data.meta) {
          try {
            item.booking_data.meta = JSON.parse(item.booking_data.meta)
          } catch (e) {
            (e)
          }
        }
        return item
      })

      const newResult = {
        data: data,
        count:
          typeof result.data.metadata !== 'undefined'
            ? result.data.metadata.total
            : data.length
      }

      return {
        data: newResult
      }
    },
    showBatchBookingRequest (item) {
      this.$router.push({
        name: 'ShowBatchBooking',
        params: { batchRequestID: item.row.id }
      })
    }
  }
}
</script>

<style lang="css" scoped>
.VueTables__search {
  padding-left: 10px !important;
}
</style>
